import { Swiper } from 'swiper';
import {
    Autoplay,
    Keyboard,
    Navigation,
    Pagination,
    EffectFade,
    EffectCards,
    Mousewheel
} from 'swiper/modules';

/**
 * Initialize the slider.
 *
 * @param {Element} container HTMLElement.
 * @param {Object}  options   Slider parameters.
 *
 * @return {Object} Returns initialized slider instance.
 *
 * @see https://swiperjs.com/swiper-api#parameters
 */
export function SwiperInit(container, options = {}) {
    const slidesPerView = parseInt(options.slidesPerView, 10);
    const modules = [Autoplay, Keyboard, Mousewheel];
    
    let effect = options.sliderEffect || 'slide';
    let cardsEffect = {};

    // Select the swiper wrapper
    const swiperWrapper = container.querySelector('.swiper-wrapper');

    if (swiperWrapper) {
        switch (effect) {
            case 'cards':
                modules.push(EffectCards);
                swiperWrapper.classList.add('swiper-effect-cards');
                cardsEffect = {
                    slideShadows: true,
                    rotate: 15,
                    perSlideOffset: 15,
                };
                break;
            case 'fade':
                modules.push(EffectFade);
                swiperWrapper.classList.add('swiper-effect-fade');
                cardsEffect = {
                    crossFade: true,
                };
                break;
            default:
                effect = 'slide';
                break;
        }
    }

    const parameters = {
        modules,
        autoplay: options?.autoplay ?? true,
        autoHeight: options?.autoHeight ?? false,
        grabCursor: options?.grabCursor ?? false,
        initialSlide: 0,
        keyboard: true,
        simulateTouch: options?.simulateTouch ?? true,
        centeredSlides: options?.centeredSlides ?? (effect ? false : true),
        spaceBetween: 20,
         breakpoints: {
          750:{
             slidesPerView: 1,
             spacePerView: 0
          },
           769: {
              slidesPerView: (isNaN(slidesPerView) || slidesPerView === 0) ? '1' : slidesPerView,  // Mobile settings
              spaceBetween: 15,  // Mobile spacing
            },
          },
        rewind: true,
        effect, // Apply the effect
        cardsEffect, // Apply effect-specific parameters directly
    };

    // Check effect parameters
    console.log(parameters);

    if (options.navigation) {
        modules.push(Navigation);
        parameters.navigation = {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        };
    }

    if (options.pagination) {
        modules.push(Pagination);
        parameters.pagination = {
            el: '.swiper-pagination',
            clickable: true,
            type: "fraction",
        };
    }

    return new Swiper(container, parameters);
}
